<template>
  <v-container>
    <v-sheet>
      <v-card flat class="px-4 pb-4">
        <v-row align="center" dense>
          <v-col align="left" cols="12" sm="8">
            <v-btn class="mr-4" color="primary" v-on:click="setToday">
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" v-on:click="prev">
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" v-on:click="next">
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-card-title v-if="$refs.calendar" class="d-inline-flex">
              {{ $refs.calendar.title }}
            </v-card-title>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select v-model="type" outlined dense label="Layout" hide-details :items="types" item-text="name" item-value="type"></v-select>
          </v-col>
        </v-row>
        <v-row align="center" dense>
          <v-col cols="12" sm="6">
            <v-select v-model="filter" outlined dense label="View By" hide-details :items="filters" item-text="name" item-value="type" v-on:change="onFilterChange"></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-combobox label="Filter" outlined dense hide-details v-model="criteria.selected" :items="criteria.items" :item-text="criteria.itemText" item-value="id" :loading="criteria.loading" :disabled="filter == null" v-on:update:search-input="onUpdateSearchInputCriteria">
              <template slot="append" v-if="criteria.allowScanner">
                <v-tooltip bottom v-if="hasCamera">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on:click.stop.prevent="criteria.showScannerCriteria = !criteria.showScannerCriteria" v-bind="attrs" v-on="on">mdi-barcode-scan</v-icon>
                  </template>
                  <span>Scan Code</span>
                </v-tooltip>
              </template>
            </v-combobox>
            <StreamBarcodeReader v-if="criteria.showScannerCriteria" v-on:decode="onScannerDecodeCriteria"></StreamBarcodeReader>
          </v-col>
        </v-row>
      </v-card>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="computedEvents"
        :event-color="getEventColor"
        :type="type"
        :event-more="true"
        event-overlap-mode="column"
        v-on:click:event="showEvent"
        v-on:click:more="viewDay"
        v-on:click:date="viewDay"
        v-on:change="updateRange"
      ></v-calendar>
      <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
        <v-card color="grey lighten-4" min-width="350px" flat v-if="selectedEvent != {} && selectedEvent.batch != null">
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
          </v-toolbar>
          <v-card-title>
            {{selectedEvent.batch.in_date == null ? 'Not Yet Returned' : 'Returned'}}
          </v-card-title>
          <v-card-text class="text-left">
            <v-row dense><v-col>
                <span class="font-weight-bold">Type: </span>{{selectedEvent.batch.outtype.name}}
            </v-col></v-row>
            <v-row dense><v-col>
                <span class="font-weight-bold">Person In Charge: </span>{{selectedEvent.batch.manager.name}}
            </v-col></v-row>
            <v-row dense><v-col>
                <span class="font-weight-bold">Borrow Date: </span>{{formatDT(selectedEvent.batch.out_date)}}
            </v-col></v-row>
            <v-row dense><v-col>
                <span class="font-weight-bold">Expected Return Date: </span>{{formatDT(selectedEvent.batch.expected_in_date)}}
            </v-col></v-row>
            <v-row dense><v-col>
                <span class="font-weight-bold">Actual Return Date: </span>{{selectedEvent.batch.in_date ? formatDT(selectedEvent.batch.in_date) : '--'}}
            </v-col></v-row>
            <v-row dense v-if="selectedEvent.batch.inouts"><v-col>
                <span class="font-weight-bold">Borrowed Assets Count: </span>{{selectedEvent.batch.inouts.length}}
            </v-col></v-row>
            <v-row dense v-if="selectedEvent.batch.comment"><v-col>
                {{selectedEvent.batch.comment}}
            </v-col></v-row>
            <v-row dense v-if="selectedEvent.batch.commentInOut"><v-col>
                {{selectedEvent.batch.commentInOut}}
            </v-col></v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" v-if="selectedEvent.batch.outtype_id == 1001" v-on:click="viewItem(selectedEvent.batch)"><v-icon left>mdi-chevron-right</v-icon>View</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="secondary" v-on:click="selectedOpen = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </v-container>
</template>


<script>
var moment = require('moment-timezone');

export default {
  data: function() {
    return {
      search: "",
      type2: null,
      focus: '',
      type: 'month',
      types: [
        {type: 'month', name: 'Month'},
        {type: 'week', name: 'Week'},
        {type: 'day', name: 'Day'},
        {type: '4day', name: '4 Days'},
      ],
      filter: null,
      filters: [
        // {type: 'all', name: 'Show All'},
        {type: 'asset', name: 'By Asset'},
        {type: 'batch', name: 'By Batch'},
        {type: 'user', name: 'By User'},
      ],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      batches: [],
      colors: ['blue', 'green', 'orange', 'indigo', 'deep-purple', 'cyan', 'grey darken-1'],
      extraItems: {
        users: null,
        assets: null,
      },
      criteria: {
        allowScanner: false,
        showScannerCriteria: false,
        loading: false,
        selected: null,
        items: [],
        itemText: 'name',
      }
    }
  },
  computed: {
    computedEvents: {
      get: function() {
        // var _this = this;

        var events = [];

        if(this.filter == 'batch' || this.filter == 'user'){
          for (let i = 0; i < this.batches.length; i++) {
            const batch = this.batches[i];
            if(this.criteria.selected != '' && this.criteria.selected != null && this.criteria.selected.id != null){
              if(this.filter == 'batch' && batch.id != this.criteria.selected.id){
                continue;
              }else if(this.filter == 'user' && batch.manager_id != this.criteria.selected.id){
                continue;
              }
            }

            let name = batch.manager.name;
            let start = this.formatDT(batch.out_date);
            let end = batch.in_date ? this.formatDT(batch.in_date) : this.formatDT(batch.expected_in_date);
            let color = this.colors[batch.outtype_id - 1001];
            let timed = true;

            events.push({ name: name, start: start, end: end, color: color, timed: timed, batch: batch });
          }
        }else if(this.filter == 'asset'){
          for (let i = 0; i < this.batches.length; i++) {
            const batch = this.batches[i];
            for (let j = 0; j < batch.inouts.length; j++) {
              const inout = batch.inouts[j];
              
              if(this.criteria.selected != '' && this.criteria.selected != null && this.criteria.selected.id != null){
                if(inout.asset_id != this.criteria.selected.id){
                  continue;
                }
              }

              let name = inout.asset.name;
              let start = this.formatDT(inout.out_date);
              let end = inout.in_date ? this.formatDT(inout.in_date) : this.formatDT(inout.expected_in_date);
              let color = this.colors[batch.outtype_id - 1001];
              let timed = true;

              var details = {
                id: batch.id,
                outtype: batch.outtype,
                outtype_id: batch.outtype_id,
                comment: batch.comment,
                commentInOut: inout.comment,
                out_date: inout.out_date,
                in_date: inout.in_date,
                expected_in_date: inout.expected_in_date,
                manager: batch.manager,
                manager_id: batch.manager_id,
              }

              events.push({ name: name, start: start, end: end, color: color, timed: timed, batch: details });
            }
          }
        }



        return events;
      }
    }
   },
  created() {
    
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.getAllItems();
    this.getExtraItems();
  },
  methods: {
    getAllItems: function() {
      var _this = this;
      var url = '/inoutsbatch/getallWithInOuts'
      this.get(url, function(res){
        _this.batches = res['data']['data']
        _this.filter = 'batch'
        _this.onFilterChange('batch')
      })
    },
    getExtraItems: function() {
      var _this = this;
      this.get('/asset/getall', function(res){
        _this.extraItems.assets = res['data']['data']
      })
      this.get('/user/getall', function(res){
        _this.extraItems.users = res['data']['data']
      })
    },
    viewItem: function(item) {
        if(item){
            if(item.id)
                this.navTo("/Borrows/Details/" + item.id);
            else
                this.navTo("/Borrows/Details/-1");
        }
        else
            this.navTo("/Borrows/Details");
    },
    onFilterChange: function(e) {
      var _this = this;
      console.log(e);
      this.criteria.selected = null;
      this.criteria.showScannerCriteria = false;
      this.criteria.allowScanner = false;
      switch (e) {
        case 'asset':
          this.criteria.items = this.extraItems.assets;
          this.criteria.allowScanner = true;
          this.criteria.itemText = 'name';
          break;
        case 'batch':
          this.criteria.items = JSON.parse(JSON.stringify(this.batches));
          this.criteria.items.sort((a, b) => {
            return moment(a.out_date) < moment(b.out_date);
          });
          this.criteria.allowScanner = false;
          this.criteria.itemText = item => {
            var end = item.in_date ? _this.formatD(item.in_date) : _this.formatD(item.expected_in_date);
            var status = item.in_date == null ? 'Not Yet Returned' : 'Returned';
            return item.manager.name + ': ' + _this.formatD(item.out_date) + ' - ' + end + ' (' + status + ')';
          };
          break;
        case 'user':
          this.criteria.items = this.extraItems.users;
          this.criteria.allowScanner = true;
          this.criteria.itemText = 'name';
          break;
        default:
          break;
      }
    },
    onUpdateSearchInputCriteria: function(e) {
      if(!e) return;
      var code = e.match(/\d/g);
      if(!code) return;
      code = code.join('')
      console.log(code);
      if(code.length != 16) return;
      var _this = this;

      var url = '';
      if(this.filter == 'user')
        url = '/user/getByCode/'
      else if(this.filter == 'asset')
        url = '/asset/getByCode/'
      else
        return;

      this.criteria.loading = true;

      this.get(url + code, function(res){
        if(res['data']['data']){
          _this.criteria.selected = null
          _this.$nextTick(() => {
            _this.criteria.selected = res['data']['data'];
          })
        }else{
          _this.criteria.selected = null;
        }
        _this.criteria.loading = false;
      }, function(){
        _this.criteria.loading = false;
      }, {showSuccess: false, showError: true, showLoading: false})
    },
    onScannerDecodeCriteria: function(result) {
      // var _this = this;
      this.criteria.selected = result;
      this.criteria.showScannerCriteria = false;
    },

    // Calendar
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      // const events = []

      // const min = new Date(`${start.date}T00:00:00`)
      // const max = new Date(`${end.date}T23:59:59`)
      // const days = (max.getTime() - min.getTime()) / 86400000
      // const eventCount = this.rnd(days, days + 20)

      // for (let i = 0; i < eventCount; i++) {
      //   const allDay = this.rnd(0, 3) === 0
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
      //   const second = new Date(first.getTime() + secondTimestamp)

      //   events.push({
      //     name: this.names[this.rnd(0, this.names.length - 1)],
      //     start: first,
      //     end: second,
      //     color: this.colors[this.rnd(0, this.colors.length - 1)],
      //     timed: !allDay,
      //   })
      // }

      // this.events = events
    },
  }
}
</script>